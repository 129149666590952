.parentContainer {
  display: flex;
  position: relative;
  height: auto;
  width: 100%;
  justify-content: center;
  margin: 8% 0%;
  align-items: stretch;
}
.childCotainer{
  display: flex;
  width: 80%;
  height: auto;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
}
.imageContainer {
  order: 1;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vw;
}
.ellipseBackGround {
  position: absolute;
  width: 100%;
  left: -50%;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: antiquewhite;
}
.imageBox {
  box-shadow: var(--image-shadow);
  width: 100%;
  height: 45%;
  z-index: 2;
}
.educationTextAlign {
  text-align: right;
 margin-left: auto;
}
.descriptorStyle {
  width: 100%;
}
.logoTextContainer {
  z-index: 2;
  align-self: center;
  order: 1;
}
.ellipse {
  height: 100%;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .parentContainer{
    min-height: 450px;
  }
  .imageBox {
    min-height: 240px;
    height: 35%;
    width: 80%;
  }
  .imageContainer {
    flex: 1.1;
    height: auto;
  }
  .ellipseBackGround {
    align-self: center;
    width: 70%;
    left: -35%;
  }
  .logoTextContainer{
    flex: 0.9;
  }
  .educationTextAlign {
    text-align: left;
    margin-left: 0;
    margin-right: auto;
  }
  .descriptorStyle {
    width: 90%;
  }
}
@media screen and (min-width:1366px){
  .parentContainer{
    min-height: 800px;
  }
  .imageBox{
    min-height: 300px;
  }
}
@media screen and (min-width:1920px){
  .parentContainer{
    min-height: 1080px;
  }

  .imageBox{
    min-height: 400px;
  }
  
}

@media screen and (min-width:2560px){
  .parentContainer{
    min-height: 1400px;
  }
  .imageBox{
    min-height: 560px;
  }
}